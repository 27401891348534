export interface TableField {
  field: string | string[] | any;
  header: string;
  isFilter?: boolean;
  isSorteable?: boolean;
  isTooltip?: boolean;
  tooltipPosition?: TooltipPosition;
  types?: FieldType[];
  action?: ActionField;
  separator?: string;
  filter?: Filter;
  sortBy?: string;
}

export enum FieldType {
  DECIMAL = 'DECIMAL',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  ARRAY = 'ARRAY'
}

export enum FilterType {
  TEXT = 'text',
  MULTISELECT = 'multiselect'
}

export enum TableSort {
  ASC = 1,
  DESC = -1
}

export interface ActionField {
  icon: string;
  tooltip: string;
  disabled: (..._: any) => boolean;
}

export interface Filter {
  filterType: FilterType;
  filterBy?: string;
  placeholder?: string;
  getMultiselectFilter?: Function;
}

export enum TooltipPosition {
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom'
}
